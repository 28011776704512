import styled from "styled-components";

const StyledPricingPointSection = styled.div`
	@media only screen and (min-width: 2350px) {
		.pricing_point-item {
			margin: 30px 0 30px 0;
		}
		.point {
			width: 60px;
			height: 60px;
			line-height: 60px;
			font-size: 22px;
		}
		.point-text {
			font-size: 22px;
		}
	}

	width: 100%;
	margin: auto;
	text-align: center;
	padding: 70px 0;

	.pricing_point-items {
		margin: auto;
		width: 100%;
		max-width: 800px;
	}
	.pricing_point-item {
		margin: 50px 0 30px 0;
	}
	.point {
		margin: auto;
		width: 50px;
		height: 50px;
		line-height: 50px;
		color: #2aad7c;
		border-radius: 50%;
		border: 1px solid #2aad7c;
		margin-bottom: 15px;
	}
`;

export default StyledPricingPointSection;
