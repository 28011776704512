import React from "react";
import PropTypes from "prop-types";

function PricingPoint({ point, pointDescription }) {
	return (
		<div className="pricing_point-items">
			<div className="pricing_point-item">
				<div className="point">{point}</div>
				<div className="point-text">
					<p>{pointDescription}</p>
				</div>
			</div>
		</div>
	);
}

PricingPoint.propTypes = {
	point: PropTypes.string.isRequired,
	pointDescription: PropTypes.string.isRequired
};

export default PricingPoint;
