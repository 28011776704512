import React from "react";
import styled from "styled-components";

const StyledMain = styled.main`
	margin-top: 0;
	background: #ffffff;
`;

const Main = ({ ...rest }) => <StyledMain {...rest} />;

export default Main;
