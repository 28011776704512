import styled from "styled-components";

const StyledPricingSection = styled.div`
	position: relative;
	@media only screen and (min-width: 2350px) {
		.pricing_size-section {
			margin: 80px 0 80px 0;
		}
		.pricing_size-section.small {
			min-height: 805px;
		}
		.pricing_size-section.medium {
			min-height: 800px;
		}
		.pricing_size-section.big {
			min-height: 880px;
		}
		.pricing_size-content {
			width: 2300px;
		}
		.pricing_size-content-green {
			margin-bottom: 50px;
		}
		.pricing_size-label {
			width: auto;
			max-width: 250px;
			font-size: 20px;
		}
		.pricing_size-strong {
			font-weight: 500;
			font-size: 40px;
			margin: 20px 0 20px 0;
		}
		.pricing_size-text {
			font-size: 20px;
			margin: 20px 0 20px 0;
		}
	}

	.pricing_size-section {
		background-repeat: no-repeat;
		position: absolute !important;
		top: 50%;
		transform: translateY(-50%);
		width: 50%;
		max-width: 575px;
	}
	.pricing_size-section.small {
		right: 0;
		min-height: 605px;
	}
	.pricing_size-section.medium {
		left: 0;
		min-height: 700px;
	}
	.pricing_size-section.big {
		right: 0;
		min-height: 680px;
	}

	.pricing_size-content {
		padding: 70px 0;
		margin: auto;
		width: 1150px;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto;
	}
	.pricing_size-content-green {
		color: #2aad7c;
		margin-bottom: 50px;
	}
	.pricing_size-label {
		background: #f0fffa;
		min-width: 100px;
		max-width: 200px;
		padding: 5px 10px 5px 10px;
		text-align: center;
		margin-top: 40px;
	}
	.pricing_size-strong {
		font-weight: 500;
		font-size: 40px;
		margin: 20px 0 20px 0;
	}
	.pricing_size-text {
		font-size: 14px;
		margin: 20px 0 20px 0;
	}

	@media only screen and (max-width: 1200px) {
		.pricing_size-section {
			min-height: 250px;
			padding: 50px 30px;
			display: none;
		}
		.pricing_size-section.small {
			min-height: 250px;
		}
		.pricing_size-section.medium {
			min-height: 250px;
		}
		.pricing_size-section.big {
			min-height: 250px;
		}
		.pricing_size-content {
			padding: 50px 30px;
			width: 100%;
			grid-template-columns: auto;
			grid-auto-rows: auto auto;
			grid-row-gap: 30px;
			box-sizing: border-box;
		}
		.pricing_size-content-green {
			color: #2aad7c;
			margin-bottom: 50px;
		}
	}
`;

export default StyledPricingSection;
