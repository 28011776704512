import React from "react";
import PropTypes from "prop-types";

function PricingSize({
	SCREENS_LABEL,
	DIFFICULTY_LABEL,
	PRICE_LABEL,
	screens,
	description,
	price,
	priceDescription
}) {
	return (
		<div className="pricing_size-content-green">
			<div className="pricing_size-label">{SCREENS_LABEL}</div>
			<div className="pricing_size-strong">{screens}</div>
			<div className="pricing_size-label">{DIFFICULTY_LABEL}</div>
			<div className="pricing_size-text">{description}</div>
			<div className="pricing_size-label">{PRICE_LABEL}</div>
			<div className="pricing_size-strong">{price}</div>
			<div className="pricing_size-text">{priceDescription}</div>
		</div>
	);
}

PricingSize.propTypes = {
	SCREENS_LABEL: PropTypes.string.isRequired,
	DIFFICULTY_LABEL: PropTypes.string.isRequired,
	PRICE_LABEL: PropTypes.string.isRequired,
	screens: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	price: PropTypes.string.isRequired,
	priceDescription: PropTypes.string.isRequired
};

export default PricingSize;
