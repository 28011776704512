import styled from "styled-components";

const StyledPricingHowSection = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: auto;
	padding: 70px 30px;
	box-sizing: border-box;

	.pricing_how-left {
		align-self: center;
	}
	.pricing_how-right {
		height: 585px;
	}

	@media only screen and (max-width: 1200px) {
		grid-template-columns: auto;
		grid-auto-rows: auto auto;
		grid-row-gap: 30px;

		.pricing_how-left {
			align-self: center;
		}
		.pricing_how-right {
			height: 250px;
		}
	}
`;

export default StyledPricingHowSection;
