import styled from "styled-components";

const StyledPricingSection = styled.div`
	text-align: center;
	padding: 50px 0;

	.pricing_button-item {
		color: #2aad7c;
		display: inline-block;
		border: 1px solid #2aad7c;
		padding: 10px;
		min-width: 200px;
	}
	.pricing_button-item:hover {
		cursor: pointer;
		background: #2aad7c;
		color: #ffffff;
	}
	.pricing_button-item a:nth-child(1) {
		background: red;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	.pricing_button-item a:nth-child(2) {
		border-left: 0;
		border-right: 0;
		border-radius: 0;
	}
	.pricing_button-item a:nth-child(3) {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
`;

export default StyledPricingSection;
