import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButtonLink = styled.div`
	.button-icon {
		margin-right: 10px;
	}
	.link {
		min-width: 50px;
		min-height: 40px;
		background: none;
		border: none;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 10px 20px 10px 20px;
		text-decoration: none;
		cursor: pointer;
		transition: color 0.2s, background-color 0.2s;
	}
	.link.green-font {
		color: #2aad7c;
		border: 1px solid #2aad7c;
	}
	.link.green-font:hover {
		background: #2aad7c;
		color: #ffffff;
		svg * {
			fill: #ffffff;
		}
	}
	.link.white-font {
		color: #ffffff;
		border: 1px solid #ffffff;
	}
	.link.white-font:hover {
		background: #ffffff;
		color: #161616;
		svg * {
			fill: #161616;
		}
	}
	.link.black-font {
		color: #161616;
		border: 1px solid #161616;
	}
	.link.black-font:hover {
		background: #161616;
		color: #ffffff;
		svg * {
			fill: #ffffff;
		}
	}
	.link.grey-font {
		color: #b0b0b0;
		border: 1px solid #b0b0b0;
	}
	.link.grey-font:hover {
		border: 1px solid #5b5b5b;
		background: #5b5b5b;
		color: #ffffff;
		svg * {
			fill: #ffffff;
		}
	}
	.link.green-bg {
		background: #2aad7c;
		color: #ffffff;
		&:hover {
			background: #ffffff;
			color: #2aad7c;
		}
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		.link {
			display: block;
			width: 100%;
			min-height: 40px;
			padding: 10px 20px 10px 20px;
			box-sizing: border-box;
			text-align: center;
		}
	}
	@media only screen and (max-width: 1200px) and (min-device-width: 1200px) {
		min-width: 220px;
		width: 250px;
		margin: auto;
	}
	.hp-main {
		margin-right: 40px;
		min-width: 175px;
		min-height: 50px;
		position: relative;
		padding: 17px;
		text-align: center;
		&.with-icon {
			padding: 17px 17px 17px 46px;
		}

		@media only screen and (max-width: 1200px) {
			width: 100% !important;
			margin: 0;
		}
	}
`;
const ButtonLink = ({
	classString,
	link,
	icon,
	label,
	external,
	alt,
	style
}) => {
	if (external || !link.startsWith("/")) {
		return (
			<StyledButtonLink className="button-link">
				<a
					href={link}
					className="link green-font small"
					target="blank"
					style={{ ...style, display: "inline-block" }}
					alt={alt}
				>
					{label}
				</a>
			</StyledButtonLink>
		);
	}
	return (
		<StyledButtonLink className="button-link">
			<Link
				className={classString}
				to={link}
				style={{ ...style, display: "inline-block" }}
				alt={alt}
			>
				{icon}
				{label}
			</Link>
		</StyledButtonLink>
	);
};

ButtonLink.propTypes = {
	classString: PropTypes.string,
	link: PropTypes.string.isRequired,
	icon: PropTypes.shape({
		prefix: PropTypes.string,
		iconName: PropTypes.string,
		icon: PropTypes.array
	}),
	label: PropTypes.string.isRequired,
	alt: PropTypes.string,
	external: PropTypes.bool,
	style: PropTypes.objectOf(PropTypes.string)
};

ButtonLink.defaultProps = {
	classString: "",
	icon: undefined,
	external: false,
	alt: "",
	style: {}
};

export default ButtonLink;
