import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Div = styled.div`
	display: block;
	position: relative;
	visibility: hidden;
`;

const Anchor = ({ id, topPosition }) => (
	<Div className="anchor" id={id} style={{ top: topPosition }} />
);

Anchor.propTypes = {
	id: PropTypes.string.isRequired,
	topPosition: PropTypes.string
};

Anchor.defaultProps = {
	topPosition: "0px"
};

export default Anchor;
