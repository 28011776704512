import React from "react";
import Scrollchor from "react-scrollchor";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Image from "../components/Image";
import ContactRow from "../components/ContactRow";
import ContentRow from "../components/ContentRow";
import Main from "../components/Main";
import CommonContent from "../components/CommonContent";
import ButtonHashLink from "../components/ButtonHashLink";
import PricingSize from "../components/PricingSize";
import PricingPoint from "../components/PricingPoint";
import Anchor from "../components/Anchor";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import CTA from "../components/CTA";
import StyledPricingSection from "../components/templateStyles/StyledPricingSection";
import StyledPricingButtons from "../components/templateStyles/StyledPricingButtons";
import StyledPricingPointSection from "../components/templateStyles/StyledPricingPointSection";
import StyledPricingHowSection from "../components/templateStyles/StyledPricingHowSection";

const Pricing = ({ data, pageContext }) => {
	const ANCHOR_OPTIONS = { duration: 400 };
	return (
		<Layout pageContext={pageContext}>
			<Main className="page-container">
				<SEO
					title={data.pricing.META_TITLE}
					description={data.pricing.META_DESCRIPTION}
					lang={pageContext.lang}
					keywords={["mobilní aplikace"]}
				/>
				<ContentRow background="white" rowContentClass="no-padding">
					<div style={{ height: "80px" }} />
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<StyledPricingHowSection>
						<CommonContent
							headline={data.pricing.HOW_HEADLINE}
							contentSize="big"
							content={data.pricing.HOW_CONTENT_1}
							wrapperClass="pricing_how-left"
						/>
						<Image
							className="pricing_how-right"
							objectFit="scale-down"
							alt={data.pricing.how_right.alt}
							data={data.pricing.how_right.image}
						/>
					</StyledPricingHowSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<StyledPricingButtons>
						<Scrollchor to="#small" animate={ANCHOR_OPTIONS} disableHistory>
							<div className="pricing_button-item">
								<div className="headline">
									<strong>{data.pricing.BUTTON_SMALL}</strong>
								</div>
								<div className="name">{data.pricing.BUTTON_MOBILE_APP}</div>
							</div>
						</Scrollchor>
						<Scrollchor to="#medium" animate={ANCHOR_OPTIONS} disableHistory>
							<div className="pricing_button-item">
								<div className="headline">
									<strong>{data.pricing.BUTTON_MEDIUM}</strong>
								</div>
								<div className="name">{data.pricing.BUTTON_MOBILE_APP}</div>
							</div>
						</Scrollchor>
						<Scrollchor to="#big" animate={ANCHOR_OPTIONS} disableHistory>
							<div className="pricing_button-item">
								<div className="headline">
									<strong>{data.pricing.BUTTON_BIG}</strong>
								</div>
								<div className="name">{data.pricing.BUTTON_MOBILE_APP}</div>
							</div>
						</Scrollchor>
					</StyledPricingButtons>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size">
					<StyledPricingSection>
						<Anchor id="small" topPosition="-90px" />
						<Image
							className="pricing_size-section small"
							objectFit="scale-down"
							objectPosition="100% 50%"
							alt={data.pricing.size_section_small.alt}
							data={data.pricing.size_section_small.image}
						/>
						<div className="pricing_size-content">
							<div className="pricing_size-content-left">
								<CommonContent
									headline={data.pricing.SMALL_HEAD}
									content={data.pricing.SMALL_CONTENT_1}
								/>
								<PricingSize
									SCREENS_LABEL={data.pricing.SCREENS_LABEL}
									DIFFICULTY_LABEL={data.pricing.DIFFICULTY_LABEL}
									PRICE_LABEL={data.pricing.PRICE_LABEL}
									lang={pageContext.lang}
									screens="1-10"
									description={data.pricing.SMALL_DIFFICULTY_TEXT}
									price={data.pricing.PRICE_SMALL}
									priceDescription={data.pricing.SMALL_PRICE_TEXT}
								/>
								<ButtonHashLink
									link={`/${pageContext.lang}/${pageContext.MENU.CONTACT.URI}#contact-form`}
									label={data.pricing.SIZE_CTA_BUTTON}
									classString="link green-font"
								/>
							</div>
							<div className="pricing_size-content-right" />
						</div>
					</StyledPricingSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size">
					<StyledPricingSection>
						<Anchor id="medium" topPosition="-90px" />
						<Image
							className="pricing_size-section medium"
							objectFit="scale-down"
							objectPosition="0 50%"
							alt={data.pricing.size_section_medium.alt}
							data={data.pricing.size_section_medium.image}
						/>
						<div className="pricing_size-content">
							<div className="pricing_size-content-left" />
							<div className="pricing_size-content-right">
								<CommonContent
									headline={data.pricing.MEDIUM_HEAD}
									content={data.pricing.MEDIUM_CONTENT_1}
								/>
								<PricingSize
									SCREENS_LABEL={data.pricing.SCREENS_LABEL}
									DIFFICULTY_LABEL={data.pricing.DIFFICULTY_LABEL}
									PRICE_LABEL={data.pricing.PRICE_LABEL}
									lang={pageContext.lang}
									screens="10-20"
									description={data.pricing.MEDIUM_DIFFICULTY_TEXT}
									price={data.pricing.PRICE_MID}
									priceDescription={data.pricing.MEDIUM_PRICE_TEXT}
								/>
								<ButtonHashLink
									link={`/${pageContext.lang}/${pageContext.MENU.CONTACT.URI}#contact-form`}
									label={data.pricing.SIZE_CTA_BUTTON}
									classString="link green-font"
								/>
							</div>
						</div>
					</StyledPricingSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size">
					<StyledPricingSection>
						<Anchor id="big" topPosition="-90px" />
						<Image
							className="pricing_size-section big"
							objectFit="scale-down"
							objectPosition="100% 50%"
							alt={data.pricing.size_section_big.alt}
							data={data.pricing.size_section_big.image}
						/>
						<div className="pricing_size-content">
							<div className="pricing_size-content-left">
								<CommonContent
									headline={data.pricing.BIG_HEAD}
									content={data.pricing.BIG_CONTENT_1}
								/>
								<PricingSize
									SCREENS_LABEL={data.pricing.SCREENS_LABEL}
									DIFFICULTY_LABEL={data.pricing.DIFFICULTY_LABEL}
									PRICE_LABEL={data.pricing.PRICE_LABEL}
									lang={pageContext.lang}
									screens="20+"
									description={data.pricing.BIG_DIFFICULTY_TEXT}
									price={data.pricing.PRICE_BIG}
									priceDescription={data.pricing.BIG_PRICE_TEXT}
								/>
								<ButtonHashLink
									link={`/${pageContext.lang}/${pageContext.MENU.CONTACT.URI}#contact-form`}
									label={data.pricing.SIZE_CTA_BUTTON}
									classString="link green-font"
								/>
							</div>
							<div className="pricing_size-content-right" />
						</div>
					</StyledPricingSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="small-padding">
					<StyledPricingPointSection>
						<h2>{data.pricing.POINTS_HEADLINE}</h2>
						<PricingPoint point="1" pointDescription={data.pricing.POINT_1} />
						<PricingPoint point="2" pointDescription={data.pricing.POINT_2} />
						<PricingPoint point="3" pointDescription={data.pricing.POINT_3} />
						<PricingPoint point="4" pointDescription={data.pricing.POINT_4} />
					</StyledPricingPointSection>
				</ContentRow>
				<ContentRow background="green-light" style={{ textAlign: "center" }}>
					<p style={{ marginBottom: "10px" }}>{data.pricing.note.text}</p>
					<p>
						<a href={data.pricing.note.link.link}>
							{data.pricing.note.link.label}
						</a>
					</p>
				</ContentRow>
				{/* <ContentRow background="green-light" rowContentClass="no-padding">
					<CTA
						headline={data.pricing.CTA_HEADLINE}
						pageContext={pageContext}
						content={data.pricing.CTA_CONTENT}
						contentSize="big"
					/>
				</ContentRow> */}
				<ContactRow pageContext={pageContext} />
			</Main>
		</Layout>
	);
};
Pricing.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};

export default Pricing;

export const query = graphql`
	query PricingQuery($lang: String!) {
		pricing: pricingJson(lang: { eq: $lang }) {
			how_right {
				alt
				image {
					extension
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
			size_section_small {
				alt
				image {
					extension
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
			size_section_medium {
				alt
				image {
					extension
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
			size_section_big {
				alt
				image {
					extension
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
			HOW_HEADLINE
			META_TITLE
			META_DESCRIPTION
			HOW_CONTENT_1
			HOW_CONTENT_2
			BUTTON_SMALL
			BUTTON_MEDIUM
			BUTTON_BIG
			BUTTON_MOBILE_APP
			SMALL_HEAD
			SMALL_CONTENT_1
			SMALL_CONTENT_2
			SCREENS_LABEL
			DIFFICULTY_LABEL
			PRICE_LABEL
			SIZE_CTA_BUTTON
			PRICE_SMALL
			PRICE_MID
			PRICE_BIG
			SMALL_DIFFICULTY_TEXT
			SMALL_PRICE_TEXT
			MEDIUM_HEAD
			MEDIUM_CONTENT_1
			MEDIUM_CONTENT_2
			MEDIUM_DIFFICULTY_TEXT
			MEDIUM_PRICE_TEXT
			BIG_HEAD
			BIG_CONTENT_1
			BIG_CONTENT_2
			BIG_DIFFICULTY_TEXT
			BIG_PRICE_TEXT
			POINTS_HEADLINE
			POINT_1
			POINT_2
			POINT_3
			POINT_4
			CTA_HEADLINE
			CTA_CONTENT
			CTA_BUTTON
			note {
				text
				link {
					link
					label
				}
			}
		}
	}
`;
